import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {

  connect() {
    this.modal = new bootstrap.Modal(this.element)
    this.element.addEventListener('turbo:frame-load', (event) => {
      this.open()
    })
  }

  open() {
    this.modal.show()
  }

  close(event) {
    event.preventDefault();
    this.modal.hide()
  }
}

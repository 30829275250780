import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']

  initialize() {
    this.config = { theme: 'light' }
    this.html = document.getElementsByTagName('html')[0];
  }

  connect() {
    let themeColorToggle = document.getElementById('light-dark-mode');
    console.debug("Dark Mode Controller Connected", themeColorToggle)
    this.setDefaultLayoutColor();
  }

  toggle(e) {
    if (this.config.theme === 'light') {
      this.changeLayoutColor('dark');
      this.iconTarget.classList.toggle( 'fa-sun', 'fa-moon');
      this.iconTarget.classList.add('fa-duotone' )
    } else {
      this.changeLayoutColor('light');
      this.iconTarget.classList.toggle( 'fa-moon', 'fa-sun');
      this.iconTarget.classList.add('fa-duotone');
    }

    window.localStorage.setItem('dark-mode-theme', this.config.theme);
  }

  changeLayoutColor(color) {
    this.config.theme = color;
    this.html.setAttribute('data-theme', color);
  }

  loadConfig() {
    return window.localStorage.getItem('dark-mode-theme') || 'light';
  }

  setDefaultLayoutColor() {
    this.changeLayoutColor(this.loadConfig());
  }
}

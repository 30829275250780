import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    connect() {
        console.debug("ColumnChart#connect")
        var colors = ["#727cf5", "#e3eaef", "#fa5c7c"],
        dataColors = $("#basic-column").data("colors"),
        options = {
            chart: {
                height: 396,
                type: "bar",
                toolbar: {
                    show: !1
                }
            },
            plotOptions: {
                bar: {
                    horizontal: !1,
                    endingShape: "rounded",
                    columnWidth: "55%"
                }
            },
            dataLabels: {
                enabled: !1
            },
            stroke: {
                show: !0,
                width: 2,
                colors: ["transparent"]
            },
            colors: colors = dataColors ? dataColors.split(",") : colors,
            series: [{
                name: "Net Profit",
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: "Revenue",
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }, {
                name: "Free Cash Flow",
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }],
            xaxis: {
                categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"]
            },
            legend: {
                offsetY: 7
            },
            yaxis: {
                title: {
                    text: "$ (thousands)"
                }
            },
            fill: {
                opacity: 1
            },
            grid: {
                row: {
                    colors: ["transparent", "transparent"],
                    opacity: .2
                },
                borderColor: "#f1f3fa",
                padding: {
                    bottom: 5
                }
            },
            tooltip: {
                y: {
                    formatter: function(t) {
                        return "$ " + t + " thousands"
                    }
                }
            }
        },
        chart = new ApexCharts(document.querySelector("#mrr-chart"), options),
        colors = (chart.render(), ["#727cf5"]),
        dataColors = $("#datalabels-column").data("colors"),
        options = {
            chart: {
                height: 380,
                type: "bar",
                toolbar: {
                    show: !1
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: "top"
                    }
                }
            },
            dataLabels: {
                enabled: !0,
                formatter: function(t) {
                    return t + "%"
                },
                offsetY: -25,
                style: {
                    fontSize: "12px",
                    colors: ["#304758"]
                }
            },
            colors: colors = dataColors ? dataColors.split(",") : colors,
            legend: {
                show: !0,
                horizontalAlign: "center",
                offsetX: 0,
                offsetY: -5
            },
            series: [{
                name: "Inflation",
                data: [2.3, 3.1, 4, 10.1, 4, 3.6, 3.2, 2.3, 1.4, .8, .5, .2]
            }],
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                position: "top",
                labels: {
                    offsetY: 0
                },
                axisBorder: {
                    show: !1
                },
                axisTicks: {
                    show: !1
                },
                crosshairs: {
                    fill: {
                        type: "gradient",
                        gradient: {
                            colorFrom: "#D8E3F0",
                            colorTo: "#BED1E6",
                            stops: [0, 100],
                            opacityFrom: .4,
                            opacityTo: .5
                        }
                    }
                },
                tooltip: {
                    enabled: !0,
                    offsetY: -10
                }
            },
            fill: {
                gradient: {
                    enabled: !1,
                    shade: "light",
                    type: "horizontal",
                    shadeIntensity: .25,
                    gradientToColors: void 0,
                    inverseColors: !0,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [50, 0, 100, 100]
                }
            },
            yaxis: {
                axisBorder: {
                    show: !1
                },
                axisTicks: {
                    show: !1
                },
                labels: {
                    show: !1,
                    formatter: function(t) {
                        return t + "%"
                    }
                }
            },
            title: {
                text: "Monthly Inflation in Argentina, 2002",
                floating: !0,
                offsetY: 360,
                align: "center",
                style: {
                    color: "#444"
                }
            },
            grid: {
                row: {
                    colors: ["transparent", "transparent"],
                    opacity: .2
                },
                borderColor: "#f1f3fa"
            }
        }
    }

}

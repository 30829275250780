import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ["filter"]
  }

  connect() {
    this.params = new URLSearchParams(window.location.search)
    this.params.delete('page')
    this.timeout = null
    this.restoreFocus();
  }

  filter(event) {
    event.preventDefault();
    clearTimeout(this.timeout);

    if (event.type === "change") {
      this.handleFilter(event);
    } else {
      this.timeout = setTimeout(() => {this.handleFilter(event)}, 500);
    }
  }

  handleFilter(event) {
    const name = event.target.name
    const value = event.target.value

    if(name == 'date_range') {
      this.handleDate(value)
    } else if (event.target.selectedOptions && event.target.multiple) {
      this.handleMultiselect(name, value, event.target.selectedOptions)
    }
    else if (event.target.type === 'checkbox') {
      this.handleCheckbox(name, event.target.checked)
    }
    else {
      this.handleSingleValue(name, value)
    }
    Turbo.visit(window.location.pathname + '?' + this.params.toString(), { action: 'advance' })
  }

  handleDate(value) {
    const dates = value.split(' - ')
    if(value === "") {
      this.params.set('start_date')
      this.params.set('end_date')
    }
    this.params.set('start_date', dates[0])
    this.params.set('end_date', dates[1])
  }

  handleMultiselect(name, value, options) {
    var allParams = this.params.getAll(name)
    this.params.delete(name);
    var selectedOptions = Array.from(options)
    selectedOptions.forEach(option => {
      this.params.append(name, option.value)
    })
  }

  handleSingleValue(name, value) {
    if(value != "") this.params.set(name, value)
    else this.params.delete(name);
  }

  handleCheckbox(name, checked) {
    if(checked) {
      this.params.set(name, true)
    } else {
      this.params.delete(name)
    }
  }



  restoreFocus() {
    if(!this.hasFilterTarget) { return }
    if(this.filterTarget.name !== 'q') { return ; }
    const valueLength = this.filterTarget.value.length;
    this.filterTarget.focus();

    if (this.filterTarget.setSelectionRange) {
      this.filterTarget.setSelectionRange(valueLength, valueLength);
    } else if (this.filterTarget.createTextRange) { // older browsers
      const range = this.filterTarget.createTextRange();
      range.collapse(true);
      range.moveEnd('character', valueLength);
      range.moveStart('character', valueLength);
      range.select();
    }

  }
}

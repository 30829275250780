import { Controller } from '@hotwired/stimulus'
import Tribute from "tributejs";
import Trix from 'trix';

export default class extends Controller {
  static targets = ['input'];


 connect() {
    if (document.querySelectorAll('trix-toolbar').length > 0) {
        document.querySelectorAll('trix-toolbar').forEach((toolbar) => {
          toolbar.style.display = 'none';
        })
      }
    this.editor = this.inputTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    if(this.hasInputTarget) { this.tribute.detach(this.inputTarget) }
  }

  initializeTribute() {
    this.tribute = new Tribute({
      values: this.fetchUsers,
      trigger: '@',
      lookup: function (person, mentionText) {
        return person.name + person.email;
      },
      allowSpaces: true,
      menuItemTemplate: (item) => { return `<div class="d-flex justify-content-between"><div>${item.original.name}</div><div class="text-truncate">${item.original.email}</div></div>` }
    })
    this.tribute.attach(this.inputTarget)
    this.inputTarget.addEventListener("tribute-replaced", this.replaced)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }

  replaced(event) {
    event.stopPropagation();
    let mention = event.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
   event.target.editor.insertAttachment(attachment)
  }

   _pasteHtml(html, startPos, endPos) {
     let range = this.inputTarget.editor.getSelectedRange()
     let position = range[0]
     let length = endPos - startPos

     this.inputTarget.editor.setSelectedRange([position - length, position])
     this.inputTarget.editor.deleteInDirection("backward")
   }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  autoSubmit(event) {
    this.element.requestSubmit();
  }

  submitOnEnter(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.autoSubmit();
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    this.offcanvas = new bootstrap.Offcanvas(this.element, {backdrop: false, scroll: true})
    this.element.addEventListener('turbo:frame-load', (event) => {
      this.open()
    })
  }

  open() {
    this.offcanvas.show()
  }

  close(event) {
    event.preventDefault();
    this.offcanvas.hide()
  }
}
